import * as $ from "jquery";
import { getCsrfCookie } from "./Utils";
var VoteButton = /** @class */ (function () {
    function VoteButton(container, ideaId, isVoted, onVoteUpdated) {
        var _this = this;
        // Render the button inside the container provided.
        container.html('<a class="circle-button vote-button rating-yes" href="#">' +
            '                    <i class="icon-vote-tick"></i>' +
            '                </a>');
        // Set initial state.
        this.isVoted = isVoted;
        this.ideaId = ideaId;
        this.onVoteUpdated = onVoteUpdated;
        this.updateVoteButtonInterface();
        // Vote button action.
        if (app.isLoggedIn) {
            $('.vote-button').click(function (event) {
                event.preventDefault();
                // Update state first as other methods depend on this!
                _this.isVoted = !_this.isVoted;
                // Actions after changing vote state.
                _this.updateVoteButtonInterface();
                _this.updateVoteCountInterface(_this.isVoted ? 1 : -1);
                _this.sendVoteToServer();
                if (_this.onVoteUpdated !== null) {
                    _this.onVoteUpdated(_this.ideaId, _this.isVoted); // trigger action in parent.
                }
            });
        }
        else {
            // Redirect to login or signup page.
            $('.vote-button').click(function (event) {
                event.preventDefault();
                location.href = '/accounts';
            });
        }
    }
    VoteButton.prototype.setNewIdea = function (ideaId, isVoted) {
        this.ideaId = ideaId;
        this.isVoted = isVoted;
        this.updateVoteButtonInterface();
    };
    VoteButton.prototype.updateVoteButtonInterface = function () {
        if (app.isLoggedIn && this.isVoted) {
            $(".vote-button").addClass('is-voted-up');
        }
        else {
            $(".vote-button").removeClass('is-voted-up');
        }
    };
    VoteButton.prototype.updateVoteCountInterface = function (addend) {
        var $selector = $('#voteCount');
        if ($selector.length) {
            var value = parseInt($selector.text());
            var newValue = value + addend;
            $selector.html(newValue.toString());
        }
        else {
            console.error('#voteCount element does not exist');
        }
    };
    VoteButton.prototype.sendVoteToServer = function () {
        $.ajax({
            type: "POST",
            url: "/api/ideas/" + this.ideaId + "/vote",
            headers: { 'X-CSRFToken': getCsrfCookie() },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ "newState": this.isVoted }),
            success: function (data) {
                //console.log('server data', data)
            },
            error: function () {
                alert('There was an error registering your action.');
            }
        });
    };
    return VoteButton;
}());
export { VoteButton };
