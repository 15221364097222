import * as $ from "jquery";
import { getCsrfCookie } from "./Utils";
var FavoriteButton = /** @class */ (function () {
    function FavoriteButton(container, ideaId, isFavorited) {
        var _this = this;
        // Render button inside container provided.
        container.html('<a href="" class="favorite favorite-btn">\n' +
            '                    <span class="favorite__active">Unfavorite</span>\n' +
            '                    <span class="favorite__inactive">Favorite</span>\n' +
            '               </a>');
        // Set initial state.
        this.$buttonSelector = $('.favorite-btn');
        this.ideaId = ideaId;
        this.isFavorited = isFavorited; // For typescript's benefit!
        this.updateState(isFavorited);
        // Bind clicks on button.
        if (app.isLoggedIn) {
            this.$buttonSelector.click(function (event) {
                event.preventDefault();
                var newState = !_this.isFavorited;
                var me = _this;
                $.ajax({
                    type: 'POST',
                    url: "/api/ideas/" + ideaId + "/favorite",
                    headers: { 'X-CSRFToken': getCsrfCookie() },
                    dataType: 'json',
                    contentType: 'application/json',
                    data: JSON.stringify({ "newState": newState }),
                    success: function (data) {
                        //console.log('server data', data)
                    },
                    error: function () {
                        alert('There was an error registering your action.');
                        // Undo front-end state that was optimistically updated.
                        me.updateState(!newState);
                    }
                });
                // Optimistically update interface immediately.
                _this.updateState(newState);
            });
        }
    }
    FavoriteButton.prototype.updateState = function (isFavorited) {
        this.isFavorited = isFavorited;
        if (isFavorited) {
            this.$buttonSelector.addClass('favorite--active');
        }
        else {
            this.$buttonSelector.removeClass('favorite--active');
        }
    };
    return FavoriteButton;
}());
export { FavoriteButton };
