import { Paginate } from "./Paginate";
import * as $ from "jquery";
import { IdeaModal } from "./IdeaModal";
var IdeaList = /** @class */ (function () {
    function IdeaList(isTopFiveVoting, historicalCohortStartDate, topIdeaBadgeUrl, top5IdeasBadgeUrl) {
        var _this = this;
        // If any parameters have been passed in the URL, set initial properties according to them.
        var urlParams = new URLSearchParams(window.location.search);
        this.keyword = urlParams.get('search') === null ? '' : urlParams.get('search');
        this.category = urlParams.get('category') === null ? '' : urlParams.get('category');
        this.sort = urlParams.get('sort') === null ? 'most-recent' : urlParams.get('sort');
        this.currentPage = urlParams.get('currentPage') === null ? 1 : parseInt(urlParams.get('currentPage'));
        // Idea data and component mode.
        this.ideaData = null;
        this.isWeeklyVoting = false;
        this.isHistoricalTopFive = false;
        if (isTopFiveVoting) {
            // Get top 5 lifted ideas for this week's voting.
            this.isWeeklyVoting = true;
            this.endpoint = '/api/ideas/this-weeks-voting';
            this.modal = new IdeaModal('votingModal', function (idea) { return _this.updateIdea(idea); });
        }
        else if (historicalCohortStartDate !== null) {
            // Show top 5 lifted ideas in voted order for a historical cohort.
            this.isHistoricalTopFive = true;
            this.endpoint = "/api/ideas/top-five/" + historicalCohortStartDate;
        }
        else {
            // Default search filters for all ideas page.
            this.endpoint = '/api/ideas';
            this.topIdeaBadgeUrl = topIdeaBadgeUrl;
            this.top5IdeasBadgeUrl = top5IdeasBadgeUrl;
        }
        this.fetchIdeas(true, true);
    }
    IdeaList.prototype.updateIdeaDataInState = function (ideaData) {
        this.ideaData = ideaData;
    };
    IdeaList.prototype.updateIdea = function (newIdea) {
        if (this.ideaData !== null) {
            var newIdeaData = this.ideaData;
            newIdeaData.ideas = this.ideaData.ideas.map(function (idea) {
                if (idea.id === newIdea.id) {
                    // The voting state or viewed status for this idea has been updated.
                    return newIdea;
                }
                return idea;
            });
            this.updateIdeaDataInState(newIdeaData);
            this.render(false, false, false);
        }
    };
    IdeaList.prototype.fetchIdeas = function (reRenderPagination, isFirst) {
        var me = this;
        // If operation is affecting pagination then reset to first page because current page
        // may not exist within new filtered subset.
        if (!isFirst && reRenderPagination) {
            this.currentPage = 1;
            me.updateUrlParam('currentPage', me.currentPage);
        }
        var json = {
            search: this.keyword,
            currentPage: this.currentPage,
            category: this.category,
            sort: this.sort,
        };
        var container = $('#ideaContainer');
        var ideas = $('#ideas__ideas');
        ideas.css('opacity', 0);
        $('#ideaSpinner').css('opacity', 0);
        $('#ideaSpinner').addClass('showAnimation').removeClass('hideAnimation');
        if (this.ajaxCall) {
            this.ajaxCall.abort();
        }
        this.ajaxCall = $.ajax({
            url: this.endpoint,
            type: "GET",
            data: json,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (ideaData) {
                me.updateIdeaDataInState(ideaData);
                me.render(isFirst, reRenderPagination);
            },
            error: function () { }
        });
    };
    ;
    IdeaList.prototype.render = function (first, reRenderPagination, animate) {
        if (animate === void 0) { animate = true; }
        var me = this;
        var data = this.ideaData;
        var container = $('#ideaContainer');
        var paginationDiv = null;
        if (first) {
            if (this.isAllIdeas()) {
                container.append(this.renderKeywordSearch(data));
            }
            container.append(this.renderIdeas(data, first, animate));
            container.append(this.renderSideBar(data, first));
            // Pagination not required for Top 5 ideas for voting.
            if (this.isAllIdeas()) {
                paginationDiv = $("<div id='pageNumberContainer' class='pageNumberContainer' style='width: 100%'>");
                container.append(paginationDiv);
            }
        }
        else {
            this.renderIdeas(data, first, animate);
            if (this.isAllIdeas()) {
                this.updateCategories(data);
            }
            paginationDiv = $('#pageNumberContainer');
        }
        $('#ideas__ideas').css('opacity', 1);
        $('#ideaSpinner').addClass('hideAnimation').removeClass('showAnimation');
        if (first) {
            var input = $('#keywordSearchInput');
            var searchDiv = $('.ideas__search');
            // the following was for the size animation of the keyword search input
            // gd.ui.menu.toggleNav
            // $('a[href="#search"]').on('click', function() {
            //     input.trigger('focus');
            //     input[0].scrollIntoView();
            //     window.scrollBy(0, -50);
            //     searchDiv.addClass('largeToNormal');
            // });
            // searchDiv.on('animationend', function () {
            //     searchDiv.removeClass('largeToNormal');
            // });
        }
        if (first || reRenderPagination) {
            var pageNumberClicked_1 = function () {
                $(this).parent().find('.currentPage').removeClass('currentPage');
                $(this).addClass('currentPage');
                var value = $(this).text();
                if (value === '>') {
                    me.currentPage++;
                }
                else if (value === '>>') {
                    me.currentPage += 10;
                }
                else if (value === '<') {
                    me.currentPage--;
                }
                else if (value === '<<') {
                    me.currentPage -= 10;
                }
                else {
                    me.currentPage = parseInt(value);
                }
                if (me.currentPage < 1) {
                    me.currentPage = 1;
                }
                if (me.currentPage >= data.totalPages) {
                    me.currentPage = data.totalPages;
                }
                me.updateUrlParam('currentPage', me.currentPage);
                Paginate.prototype.renderPageButtons(paginationDiv, data.totalIdeas, me.currentPage, data.ideasPerPage, data.totalPages, pageNumberClicked_1);
                me.animateThenSearch(false);
                var offset = container.offset();
                if (offset !== undefined) {
                    $('html, body').animate({ scrollTop: offset.top - 100 });
                }
            };
            this.currentPage = 1;
            Paginate.prototype.renderPageButtons(paginationDiv, data.totalIdeas, data.currentPage, data.ideasPerPage, data.totalPages, pageNumberClicked_1);
        }
    };
    ;
    IdeaList.prototype.renderIdeas = function (data, first, animate) {
        var ideasDiv = $('#ideas__ideas');
        if (ideasDiv.length > 0) {
            ideasDiv.empty();
        }
        else {
            ideasDiv = $('<div id="ideas__ideas" class="ideas__ideas">');
        }
        // Handle no results.
        if (data.ideas.length === 0) {
            if (this.isHistoricalTopFive) {
                data.message = 'No top ideas found for given week.';
            }
            else if (this.isWeeklyVoting) {
                data.message = 'No lifted ideas for voting found for this week.';
            }
            else {
                var categoryLabel_1 = '';
                data.categories.forEach(function (category) {
                    if (category.selected && category.value !== 'all') {
                        categoryLabel_1 = category.label;
                        return false;
                    }
                });
                data.message = 'No ideas found containing "' + data.search + '"';
                if (categoryLabel_1 !== '') {
                    data.message += ' in ' + categoryLabel_1;
                }
                data.message += '.';
            }
            ideasDiv.append(this.getNoResultsDiv(data.message));
            return ideasDiv;
        }
        // return this.renderIdeasAsTiles(ideasDiv, data, first);
        return this.renderIdeasAsRows(ideasDiv, data, first, animate);
    };
    ;
    IdeaList.prototype.getNoResultsDiv = function (message) {
        return $('<div class="no-results-message">' + message + '</div>');
    };
    IdeaList.prototype.renderSideBar = function (data, first) {
        var me = this;
        var sideBar = $('<div class="ideas__sidebar">').append('<div class="ideas__filter d-none d-lg-block">');
        sideBar.append(this.renderNavigation(data));
        // Most sidebar options not required when displaying Top 5 ideas for voting.
        if (this.isAllIdeas()) {
            sideBar.append('<h4 class="ideas__filter__title">Sort By</h4>');
            sideBar.append(this.renderSort(data));
            sideBar.append('<h4 class="ideas__filter__title">Filter By</h4>');
            sideBar.append(this.renderCategories(data));
        }
        return sideBar;
    };
    ;
    IdeaList.prototype.isAllIdeas = function () {
        return !this.isWeeklyVoting && !this.isHistoricalTopFive;
    };
    IdeaList.prototype.castToString = function (value, includeBrackets) {
        if (value) {
            if (includeBrackets) {
                return "(" + value.toString() + ")";
            }
            return value.toString();
        }
        return '';
    };
    ;
    IdeaList.prototype.animateThenSearch = function (reRenderPagination) {
        var me = this;
        var ideas = $('#ideas__ideas');
        $('.showAnimation').css('opacity', 1);
        $('.showAnimation').removeClass('showAnimation');
        ideas.addClass('hideAnimation');
        ideas.css('animation-duration', '0.25s');
        // Unset binding first otherwise old value for reRenderPagination will be retained.
        ideas.off('animationend').on('animationend', function () {
            if (ideas.hasClass('hideAnimation')) {
                ideas.removeClass('hideAnimation');
                me.fetchIdeas(reRenderPagination, false);
            }
        });
    };
    ;
    //
    // renderIdeasAsTiles(ideasDiv: any, data: IdeaData, first: boolean) {
    //     data.ideas.forEach(function (idea, index: number) {
    //         const ideaDiv = $('<article class="idea idea--tile">');
    //         ideasDiv.append(ideaDiv);
    //
    //         ideaDiv.append($('<span class="idea__rank">').append(idea.Rank));
    //
    //         ideaDiv.append($('<h3 class="idea__title">').append('<a href="' + idea.UrlFriendlyUrl + '">' + idea.Title + '</a>'))
    //         ideaDiv.append($('<div class="idea__author">').append('<a href="' + idea.AuthorUrl + '">' + idea.UserNameOrGdNumber + '</a>'))
    //         const region = $('<span class="idea__region">');
    //         if (idea.Country !== undefined) {
    //             region.append('<img class="flag-icon" src="' + idea.FlagUrl + '" alt="' + idea.Country + '"/>' + idea.Country);
    //             region.append(', ');
    //         }
    //
    //         const ideaDate = $('<span>').append($('<span class="idea__date">').append(idea.DateTime));
    //         ideaDiv.append($('<div class="idea__meta">').append(region).append(ideaDate));
    //
    //         const stats = $('<div class="idea__stats">');
    //
    //         let string = '0 votes';
    //         if (idea.VoteCount == 1) {
    //             string = '1 vote';
    //         } else if (idea.VoteCount > 1) {
    //             string = idea.VoteCount + ' votes';
    //         }
    //         stats.append($('<span class="idea__stat">').append('<i class="icon-tick"></i>').append(' ' + string));
    //
    //         string = '0 comments';
    //         if (idea.CommentCount == 1) {
    //             string = '1 comment';
    //         } else if (idea.CommentCount > 1) {
    //             string = idea.CommentCount + ' comments';
    //         }
    //         stats.append($('<span class="idea__stat">').append('<i class="icon-comment"></i>').append(' ' + string));
    //         ideaDiv.append(stats);
    //
    //         if (!first) {
    //             ideaDiv.css('opacity', 0);
    //             ideaDiv.addClass('showAnimation');
    //             const delay = index / 30;
    //             ideaDiv.css('animation-delay', delay + 's');
    //         }
    //     });
    //     return ideasDiv;
    // };
    IdeaList.prototype.renderIdeasAsRows = function (ideasDiv, data, first, animate) {
        // Bring into scope.
        var isWeeklyVoting = this.isWeeklyVoting;
        var modal = this.modal;
        var me = this;
        data.ideas.forEach(function (idea, index) {
            var ideaDiv = $('<article class="idea ideaRow idea--tile" style="position: relative">');
            ideasDiv.append(ideaDiv);
            // Only show idea number if not viewing as Top 5.
            var rank;
            var title;
            if (isWeeklyVoting && modal) {
                rank = $('<span class="idea__rank">').append('&nbsp;');
                title = $('<h3 class="idea__title">').append('<a href="#">' + idea.Title + '</a>');
                title.click(function (event) { return IdeaList.prototype.openModal(event, modal, idea, index, data.ideas); });
            }
            else {
                rank = $('<span class="idea__rank">').append(idea.Rank);
                var params = new URLSearchParams(me.isHistoricalTopFive ?
                    {
                        is_top5: JSON.stringify(true),
                    } :
                    {
                        category: me.category,
                        sort: me.sort,
                        search: me.keyword,
                        is_top5: JSON.stringify(false),
                    });
                title = $('<h3 class="idea__title">').append('<a href="' + idea.UrlFriendlyUrl + '?' + params.toString() + '">' + idea.Title + '</a>');
            }
            // Small Top 5 and Top Idea badges.
            if (idea.IsTopFiveLifted && me.isAllIdeas()) {
                if (idea.VotePosition == 1) {
                    // TOP idea for week.
                    title.append("<img alt=\"Top Idea\" src=\"" + me.topIdeaBadgeUrl + "\" class=\"small-top-idea-badge\" />");
                }
                else {
                    title.append("<img alt=\"Top Five Idea\" src=\"" + me.top5IdeasBadgeUrl + "\" class=\"small-top-idea-badge\" />");
                }
            }
            // Show date or score depending on sort order.
            if (me.isAllIdeas()) {
                if (me.sort == 'most-popular') {
                    if (idea.VoteScore !== null) {
                        title.append("<div class=\"date-slot\">" + idea.VoteScore + "%</div>");
                    }
                }
                else {
                    title.append("<div class=\"date-slot\">" + idea.DateTime + "</div>");
                }
            }
            // Color dot green if user has voted for or viewed this idea.
            if (idea.IsVotedByCurrentUser) {
                rank.addClass('is-voted-up');
            }
            else if (idea.IsViewedByCurrentUser) {
                rank.addClass('is-viewed-but-not-voted');
            }
            var body = $('<div>').append(idea.BodyCropped);
            ideaDiv.append($('<table width="auto">').append($('<tr>').append($('<td style="padding-right: 20px; width: 64px; vertical-align: top">').append(rank)).append($('<td style="padding-top: 5px">').append(title).append(body))));
            if (animate) {
                ideaDiv.css('opacity', 0);
                ideaDiv.addClass('showAnimation');
                var delay = index / 30;
                ideaDiv.css('animation-delay', delay + 's');
            }
        });
        return ideasDiv;
    };
    IdeaList.prototype.openModal = function (event, modal, idea, index, ideas) {
        var _this = this;
        var scopedModal = modal;
        event.preventDefault();
        modal.updateContent(idea, ideas[index + 1] !== undefined, function (e) { return _this.openModal(e, scopedModal, ideas[index + 1], index + 1, ideas); });
        modal.show();
    };
    IdeaList.prototype.updateUrlParam = function (key, value) {
        // Update url parameters and push as new entry into browser history.
        var urlParams = new URLSearchParams(window.location.search);
        urlParams.set(key, value);
        window.history.replaceState({}, '', location.pathname + "?" + urlParams);
    };
    IdeaList.prototype.renderKeywordSearch = function (data) {
        var me = this;
        var search = $('<div class="ideas__search">');
        var input = $("<input type=\"text\" id=\"keywordSearchInput\" name=\"query\" value=\"" + this.keyword + "\" placeholder=\"Search My Issue\" />");
        input.on('keyup', function () {
            clearTimeout(me.keywordSearchTimeout);
            me.keywordSearchTimeout = setTimeout(function () {
                var value = input.val();
                if (value === undefined) {
                    value = '';
                }
                value = value.toString().trim();
                if (me.keyword.trim() !== value) {
                    me.keyword = value;
                    me.updateUrlParam('search', value);
                    me.currentPage = 1;
                    me.fetchIdeas(true, false);
                }
            }, 500);
        });
        var searchButton = $('<button type="button"><i class="icon-search"></i></button>');
        // Sort control below search bar.
        var $sortLabel = $('<label class="ideas__topsort" for="top-sort-by-select">Sort by: </label>');
        var $sortSelect = $('<select id="top-sort-by-select">');
        $sortSelect.on('change', function () {
            //@ts-ignore
            me.executeSort($(this).val());
        });
        data.sort.forEach(function (sortItem, index) {
            var $sortOption = $('<option>')
                .prop('selected', sortItem.selected)
                .val(sortItem.value)
                .append(sortItem.label);
            $sortSelect.append($sortOption);
        });
        // Filter control below search bar.
        var $filterLabel = $('<label class="ideas__topfilter" for="top-filter-select">Filter by: </label>');
        var $filterSelect = $('<select id="top-filter-select">');
        $filterSelect.on('change', function () {
            //@ts-ignore
            me.executeFilter($(this).val());
        });
        data.categories.forEach(function (filterItem, index) {
            var labelAndCount = filterItem.label + " " + me.castToString(filterItem.count, true);
            var $filterOption = $("<option id=\"filter-option-" + filterItem.value + "\">")
                .prop('selected', filterItem.selected)
                .val(filterItem.value)
                .append(labelAndCount);
            $filterSelect.append($filterOption);
        });
        // Put it all together.
        var $sortContainer = $('<div class="top-sort-container">');
        var $filterContainer = $('<div class="top-filter-container">');
        $sortContainer.append($sortLabel).append($sortSelect);
        $filterContainer.append($filterLabel).append($filterSelect);
        return search.append(input).append(searchButton).append($sortContainer).append($filterContainer);
    };
    ;
    IdeaList.prototype.executeSort = function (selectedVal) {
        // Update state.
        this.sort = selectedVal;
        // Update top dropdown.
        $('#top-sort-by-select').val(selectedVal);
        // Update sidebar active link.
        var $list = $('#sidebar-sort-by-list');
        $list.find('.active').removeClass('active');
        $list.find("[data-value=\"" + selectedVal + "\"]").addClass('active');
        // Update param and send off API request.
        this.updateUrlParam('sort', selectedVal);
        this.animateThenSearch(true);
    };
    IdeaList.prototype.executeFilter = function (selectedVal) {
        // Update state.
        this.category = selectedVal;
        // Update top dropdown.
        $('#top-filter-select').val(selectedVal);
        // Update sidebar active link.
        var $list = $('#sidebar-filter-list');
        $list.find('.active').removeClass('active');
        $list.find("[data-value=\"" + selectedVal + "\"]").addClass('active');
        // Update param and send off API request.
        this.updateUrlParam('category', selectedVal);
        this.animateThenSearch(true);
    };
    IdeaList.prototype.renderNavigation = function (data) {
        var me = this;
        var ideasNavigationGroups = $('<div>');
        data.navigation.forEach(function (navigationGroup) {
            var navGroupUL = $('<ul>');
            navigationGroup.forEach(function (navigationItem) {
                var liClass = '';
                if (navigationItem.selected) {
                    liClass = 'active';
                }
                var link = $('<a>').attr('href', navigationItem.url).append(navigationItem.label);
                // link.on('click', function () {
                //     $(this).parent().parent().find('.active').removeClass('active');
                //     $(this).parent().addClass('active');
                //     me.navigation = navigationItem.value;
                //     // me.animateThenSearch();
                // });
                navGroupUL.append($('<li>').addClass(liClass).append(link));
            });
            ideasNavigationGroups.append($('<div class="ideas__filter__filters">').append(navGroupUL));
        });
        return ideasNavigationGroups;
    };
    ;
    IdeaList.prototype.renderSort = function (data) {
        var me = this;
        var sortersUL = $('<ul id="sidebar-sort-by-list">');
        data.sort.forEach(function (sortItem, index) {
            var liClass = sortItem.selected ? 'active' : '';
            var link = $('<a>').attr('href', 'javascript:void(0)').append(sortItem.label);
            link.on('click', function () {
                me.executeSort(sortItem.value);
            });
            var $listItem = $('<li>').attr("data-value", sortItem.value).addClass(liClass).append(link);
            sortersUL.append($listItem);
        });
        return $('<div class="ideas__filter__filters">').append(sortersUL);
    };
    ;
    IdeaList.prototype.updateCategories = function (data) {
        var me = this;
        data.categories.forEach(function (filterItem, index) {
            $('#filter-link-' + filterItem.value + ' .count').html(me.castToString(filterItem.count, false));
            $('#filter-option-' + filterItem.value)
                .html(filterItem.label + " " + me.castToString(filterItem.count, true));
            // const a = $('#link' + filterItem.value);
            // a.parent().show();
            // if (!filterItem.count && filterItem.value !== 'all') {
            //     a.parent().hide();
            // }
            // if (filterItem.selected && me.category !== filterItem.value) {
            //     a.parent().parent().find('.active').removeClass('active');
            //     a.parent().addClass('active');
            //     me.category = filterItem.value;
            // }
        });
    };
    ;
    IdeaList.prototype.renderCategories = function (data) {
        var me = this;
        var filtersUL = $('<ul id="sidebar-filter-list">');
        data.categories.forEach(function (filterItem, index) {
            var liClass = filterItem.selected ? 'active' : '';
            var a = $('<a id="filter-link-' + filterItem.value + '">').attr('href', 'javascript:void(0)').append(filterItem.label);
            a.on('click', function () {
                me.executeFilter(filterItem.value);
            });
            a.append($('<span class="count">').append(me.castToString(filterItem.count, false)));
            var $listItem = $('<li>').attr("data-value", filterItem.value).addClass(liClass).append(a);
            filtersUL.append($listItem);
        });
        return $('<div class="ideas__filter__filters">').append(filtersUL);
    };
    ;
    return IdeaList;
}());
export { IdeaList };
