import * as $ from "jquery";
export function Paginate() {
}
Paginate.prototype.renderPageButtons = function (el, totalItems, currentPage, itemsPerPage, totalPages, callback) {
    el = $(el);
    if ($(el).length === 0) {
        return;
    }
    var container = $('<div>');
    if (totalPages > 10) {
        if (currentPage > 6) {
            container.append(this.renderPageButton('<<', false, callback));
        }
        if (currentPage > 5) {
            container.append(this.renderPageButton('<', false, callback));
        }
    }
    if (currentPage <= 5) {
        var endPoint = 10;
        if (endPoint > totalPages) {
            endPoint = totalPages;
        }
        for (var i = 1; i <= endPoint; i++) {
            container.append(this.renderPageButton(i.toString(), (i === currentPage), callback));
        }
    }
    else if (currentPage >= totalPages - 5) {
        var startPoint = totalPages - 10;
        if (startPoint < 1) {
            startPoint = 1;
        }
        for (var i = startPoint; i <= totalPages; i++) {
            container.append(this.renderPageButton(i.toString(), (i === currentPage), callback));
        }
    }
    else {
        var startPoint = currentPage - 5;
        if (startPoint < 1) {
            startPoint = 2;
        }
        var endPoint = currentPage + 5;
        if (endPoint > totalPages) {
            endPoint = totalPages;
        }
        for (var i = startPoint; i <= endPoint; i++) {
            container.append(this.renderPageButton(i.toString(), (i === currentPage), callback));
        }
    }
    if (currentPage < totalPages && totalPages > 10) {
        if (totalPages > 1) {
            container.append(this.renderPageButton('>', false, callback));
        }
        if (currentPage < totalPages - 5) {
            container.append(this.renderPageButton('>>', false, callback));
        }
    }
    el.html(container);
};
Paginate.prototype.renderPageButton = function (pageNumber, selected, callback) {
    var span = $('<span class="pageNumber idea__rank">').append(pageNumber);
    if (selected) {
        span.addClass('currentPage');
    }
    span.on('click', callback);
    return span;
};
