import { CommentList } from "./CommentList";
import * as $ from "jquery";
import { getCsrfCookie } from "./Utils";
export function Comments() {
}
Comments.prototype.init = function () {
    var me = this;
    this.ideaId = $('.idea--details[data-idea-id]').attr('data-idea-id');
    this.addCommentAjax = false;
    $('#addCommentSubmit').on('click', function () {
        if (me.addCommentAjax) {
            return;
        }
        $('.comments__submit').hide();
        var spinner = $('#commentsSpinner');
        spinner.show();
        // const ideaID = $('.idea--details').attr('data-idea-id');
        // const commentsContainer = $('#commentsContainer');
        me.addCommentAjax = $.ajax({
            type: "POST",
            url: "/api/ideas/" + me.ideaId + "/comments",
            headers: { 'X-CSRFToken': getCsrfCookie() },
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify({ 'comment-body': $('#user-comment').val() }),
            success: function (data) {
                me.addCommentAjax = false;
                // if (!me.failureCallback('create-comment', data)) {
                // 	CommentList.prototype.loadComments(false, false);
                // } else {
                // 	spinner.hide();
                // 	$('.comments__submit').show();
                // }
                $('#user-comment').val('');
                CommentList.prototype.loadComments(false, false);
            },
            error: function () {
                $('.comments__submit').show();
                spinner.hide();
                me.addCommentAjax = false;
                CommentList.prototype.loadComments(false, false);
            }
        });
    });
    // $('ul.current-comments').on('click', 'a.comment-vote-button', function (e) {
    //     me.commentOnClick(e, newState);
    // });
};
Comments.prototype.commentOnClick = function (e, newState) {
    var $linkEle = $(e.currentTarget);
    if ($linkEle.attr('href') != "#") {
        return;
    }
    e.preventDefault();
    var wrapper = $linkEle.parents('.comment-current-voting-value');
    var currentScoreEle = wrapper.find('.comment-current-score');
    var commentId = $linkEle.parents('li').data('comment-id');
    var ideaId = $linkEle.parents('[data-idea-id]').data('idea-id');
    wrapper.addClass('hideAnimation');
    wrapper.css('animation-duration', '0.25s');
    if (this.ajaxCall) {
        this.ajaxCall.abort();
    }
    this.ajaxCall = $.ajax({
        type: 'POST',
        url: "/api/ideas/" + ideaId + "/comments/" + commentId + "/vote",
        headers: { 'X-CSRFToken': getCsrfCookie() },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ "newState": newState }),
        success: function (data) {
            wrapper.removeClass('hideAnimation');
            wrapper.addClass('showAnimation');
            wrapper.css('animation-duration', '0.25s');
            wrapper
                .removeClass('comment-voted-yes vote--up')
                .removeClass('comment-voted-no vote--down');
            if (data.comment.IsVotedDownByCurrentUser) {
                wrapper.addClass('comment-voted-no vote--down');
            }
            else if (data.comment.IsVotedUpByCurrentUser) {
                wrapper.addClass('comment-voted-yes vote--up');
            }
            wrapper.find('.vote__up .vote__value').html(data.comment.UpVotes);
            wrapper.find('.vote__down .vote__value').html(data.comment.DownVotes);
            // currentScoreEle.html(data["ratingValue"]);
        },
        error: function () {
            alert('There was an error registering your action.');
            // Undo front-end state that was optimistically updated.
        }
    });
};
// Comments.prototype.successCallback = function( $formEle, data ) {
// 	var payload = data['payload'];
//
// 	if( payload['Spam'] ) {
// 		var response = confirm("We're sorry, this comment has been flagged as a spam comment. Is this an error?");
// 		if( response ) {
// 			Comments.prototype.setNotSpam( $formEle, payload['IdeaId'], payload['Id'] );
// 		}
// 	} else {
// 		Comments.prototype.addCommentToForm( $formEle, payload );
//
// 		if( payload["Demoted"] ) {
// 			Comments.prototype.approvalRating.demoteIdeaDelegate( payload['IdeaId'], payload['IdeaApprovalRating'] );
// 		}
// 	}
// };
//
// Comments.prototype.failureCallback = function( $formEle, data ) {
// 	var payload = data['payload'];
//
// 	if( $.isArray( payload["message"] ) ) {
// 		var message = "";
//
// 		if( payload["message"].length > 1 ) {
// 			message = "Please check the following errors:\n";
// 		}
//
// 		message = message + payload["message"].join("\n");
//
// 		alert(message);
// 		return true;
// 	}
// 	return false;
// };
//
// Comments.prototype.addCommentToForm = function( $formEle, comment ) {
//
// 	var authorHtml = '<article class="comment__author author author--footer">' +
// 		'<div class="author__media"><img src="' + comment['AuthorAvatar'] + '" width="50" height="50" class="author__photo" /></div>' +
// 		'<div class="author__main">' +
// 			(
// 				( comment['AuthorId'] == null || comment['AuthorId'] == 0 ) ?
// 					'<h4 class="author__name">Anonymous</h4>'
// 					:
// 					'<h4 class="author__name"><a href="/user/' + comment['AuthorId'] + '">' + comment['UserName'] +  '</a></h4>'
// 			) +
// 			'<div class="author__meta">' +
// 				'<span class="author__date"><abbr title="A few seconds ago" class="date">A few seconds ago</abbr></span>' +
// 			'</div>' +
// 		'</div>' +
// 	'</article>';
//
// 	var html = '<li data-comment-id="' + comment['Id'] + '"><article class="comment">' +
// 			(comment['Demoted'] ? '<p class="demoted">' : '<p>') +
// 			authorHtml +
// 			'<div class="comment-body">' +
// 				Comments.prototype.fixNewLine(encodeURI( comment['CommentBody'])) +
// 			'</div>' +
// 		'</p>' +
// 	'</article></li>';
//
// 	var $parent = $formEle.parents('.comments'),
// 		$ulEle = $parent.find('ul.current-comments');
//
// 	if( $ulEle.length == 0 ) {
// 		var $firstToCommentParagraph = $parent.find('p.first-to-comment');
//
// 		$ulEle = $('<ul class="current-comments"></ul>');
// 		$ulEle.insertBefore($firstToCommentParagraph);
// 		$firstToCommentParagraph.remove();
// 	}
//
// 	$ulEle.append( html );
//
// 	$formEle
// 		.find('textarea')
// 		.val('')
// 		.trigger('update');
//
// 	$formEle.find('input[type="hidden"][name="demote-idea"]').remove();
// };
// Comments.prototype.customValidate = function ($formEle) {
//     return $.trim($formEle.find('textarea').val()) != '';
// };
// Comments.prototype.fixNewLine = function (str) {
//     if (typeof str === 'string') {
//         // return str.replace(/\n/g, "<br />");
//         // TODO might not be working, need to verify
//         return str.replace(encodeURI("\n"), "<br />");
//     }
//
//     return str;
// };
// Comments.prototype.setNotSpam = function ($formEle, ideaId, commentId) {
//     $.get("/WebServices/idea/comments/not_spam/" + ideaId + "/" + commentId, function (data) {
//         var payload = data['payload'];
//         if (payload != null) {
//             $formEle
//                 .find('textarea')
//                 .val('')
//                 .trigger('update');
//
//             alert(payload['message']);
//         }
//     })
// };
