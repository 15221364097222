// Gets the value of a cookie for given name.
export var getCookie = function (name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) {
        //@ts-ignore
        return parts.pop().split(';').shift();
    }
    return null;
};
export var getCsrfCookie = function () {
    return getCookie('csrftoken');
};
