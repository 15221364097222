import * as $ from "jquery";
var HamburgerMenu = /** @class */ (function () {
    function HamburgerMenu() {
        var _this = this;
        $('.header__toggle').on('click', function (e) { return _this.toggleNav(e); });
        this.isOpen = false;
    }
    ;
    HamburgerMenu.prototype.toggleNav = function (e) {
        e.preventDefault();
        if (this.isOpen) {
            this.hideNav();
        }
        else {
            this.showNav();
        }
    };
    ;
    HamburgerMenu.prototype.showNav = function () {
        this.isOpen = true;
        $('body').addClass('overlay-open');
        $('#header').addClass('header--nav-open');
    };
    ;
    HamburgerMenu.prototype.hideNav = function () {
        this.isOpen = false;
        $('body').removeClass('overlay-open');
        $('#header').removeClass('header--nav-open');
    };
    ;
    return HamburgerMenu;
}());
export { HamburgerMenu };
