import * as $ from "jquery";
export function SubmitIdea() {
}
//goog.require( 'barn.rest.ajaxFileUpload' );
//goog.require( 'gd.ui.ajaxForms' );
SubmitIdea.prototype.categoryDescriptionElement_ = null;
// SubmitIdea.prototype.redirectUrl_ = null;
// SubmitIdea.prototype.imageSuccess_ = false;
SubmitIdea.prototype.init = function () {
    $("select.category-description").on('change', SubmitIdea.prototype.showCategoryDescription);
    // $( "#submit-idea-form" ).on( 'submit', function ( e ) {
    // 	e.preventDefault();
    //
    // 	SubmitIdea.prototype.uploadImage( $( e.currentTarget ) )
    // 		.then( function () {
    // 			gd.ui.ajaxForms.handleSubmit( e );
    // 		} )
    // } );
    // gd.ui.ajaxForms.addFailureCallback( 'idea-create', SubmitIdea.prototype.message.ajaxFailure );
};
SubmitIdea.prototype.showCategoryDescription = function (e) {
    var selectedValue = e.target.value;
    var descriptionItem = gdCatDescriptions.filter(function (item) { return item.id == selectedValue; })[0];
    if (!descriptionItem) {
        SubmitIdea.prototype.categoryDescriptionElement_.remove();
        SubmitIdea.prototype.categoryDescriptionElement_ = null;
    }
    else {
        // Create form-text element if not present.
        if (SubmitIdea.prototype.categoryDescriptionElement_ == null) {
            SubmitIdea.prototype.categoryDescriptionElement_ = $('<p class="small mt-1"></p>');
            SubmitIdea.prototype.categoryDescriptionElement_.insertAfter($(e.target));
        }
        SubmitIdea.prototype.categoryDescriptionElement_.text(descriptionItem.description);
    }
};
// SubmitIdea.prototype.uploadImage = function ( $formEle ) {
// 	var deferred = $.Deferred();
//
// 	if( SubmitIdea.prototype.imageSuccess_ || $( "#idea-image" ).val().length == 0 ) {
// 		setTimeout( function () {
// 			deferred.resolve();
// 		}, 1 );
// 	} else {
// 		barn.rest.ajaxFileUpload( {
// 			fileElementId: "idea-image",
// 			url: "/WebServices/idea/submit/upload",
// 			secureuri: false,
// 			dataType: 'json',
// 			type: "POST",
// 			data: { },
// 			/**
// 			 *
// 			 * @param {{data: Object}} payload the data
// 			 * @param {boolean} status the status
// 			 */
// 			success: function ( data, status ) {
// 				var payload = data['payload'];
//
// 				if( data['success'] ) {
// 					SubmitIdea.prototype.imageSuccess_ = true;
//
// 					var parent = $( "#idea-image" ).parent();
// 					parent.prev().remove();
// 					parent.remove();
//
// 					deferred.resolve();
// 				} else {
// 					SubmitIdea.prototype.message.showError( $formEle, payload["message"] );
// 				}
// 			},
// 			error: function ( data, status, e ) {
// 				SubmitIdea.prototype.message.showError( $formEle, "Sorry! Something went wrong while submitting your idea. Please contact us if this keeps happening." );
// 			}
// 		} );
// 	}
//
// 	return deferred.promise();
// };
