import * as $ from "jquery";
var CountdownTimer = /** @class */ (function () {
    // private readonly secondsSpan: JQuery;
    function CountdownTimer(utcDeadline) {
        var _this = this;
        this.utcDeadline = utcDeadline;
        this.daysSpan = $('#ctimer-days');
        this.hoursSpan = $('#ctimer-hours');
        this.minutesSpan = $('#ctimer-minutes');
        // this.secondsSpan =  $('#ctimer-seconds');
        // Initialise display.
        this.updateDisplay();
        // Periodic: Since we are not displaying seconds ATM, update every 10s to reduce JS load.
        var updateIntervalSeconds = 10;
        setInterval(function () { return _this.updateDisplay(); }, (updateIntervalSeconds * 1000));
    }
    CountdownTimer.prototype.getTimeRemaining = function () {
        var now = new Date();
        var nowUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        var total = Date.parse(this.utcDeadline) - nowUTC.getTime();
        // const seconds: number = Math.floor((total / 1000) % 60);
        var minutes = Math.floor((total / 1000 / 60) % 60);
        var hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        var days = Math.floor(total / (1000 * 60 * 60 * 24));
        return {
            total: total > 0 ? total : 0,
            days: days > 0 ? days : 0,
            hours: hours > 0 ? hours : 0,
            minutes: minutes > 0 ? minutes : 0,
        };
    };
    CountdownTimer.prototype.pluralise = function (count, text) {
        if (count === 1) {
            return count + " " + text;
        }
        return count + " " + text + "s";
    };
    CountdownTimer.prototype.updateDisplay = function () {
        var parts = this.getTimeRemaining();
        this.daysSpan.text(this.pluralise(parts.days, 'day'));
        this.hoursSpan.text(this.pluralise(parts.hours, 'hour'));
        this.minutesSpan.text(this.pluralise(parts.minutes, 'minute'));
        // this.secondsSpan.text(this.pluralise(parts.seconds, 'second'));
    };
    return CountdownTimer;
}());
export { CountdownTimer };
