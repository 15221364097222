import * as $ from "jquery";
var Tooltips = /** @class */ (function () {
    function Tooltips() {
        $(function () {
            //@ts-ignore
            $('[data-toggle="tooltip"]').tooltip();
        });
    }
    return Tooltips;
}());
export { Tooltips };
