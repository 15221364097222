// Declare variables from other files.
import { CountdownTimer } from "./CountdownTimer";
// Import modules
import 'bootstrap';
import { HamburgerMenu } from './HamburgerMenu';
import { IdeaList } from './IdeaList';
import { Background } from "./Backgrounds";
import { FormElements } from "./FormElements";
import { SubmitIdea } from "./SubmitIdea";
import { Idea } from "./Idea";
import { Comments } from "./Comments";
import { CommentList } from "./CommentList";
import { SiteStats } from "./SiteStats";
// Import SCSS.
import "../scss/app.scss";
import { Tooltips } from "./Tooltips";
import * as $ from "jquery";
import { FavoriteButton } from "./FavoriteButton";
// Initialise components
// Required for all pages.
var hamburgerMenu = new HamburgerMenu();
var animatedBackground = new Background();
FormElements.prototype.init();
// Required for specific pages.
if (typeof (gdInitIdeasList) !== 'undefined' && gdInitIdeasList) {
    var ideaList = new IdeaList(typeof (gdTopFiveVoting) !== 'undefined' && gdTopFiveVoting, typeof (gdHistoricalCohortStartDate) === 'undefined' ? null : gdHistoricalCohortStartDate, typeof (gdTopIdeaBadgeUrl) === 'undefined' ? undefined : gdTopIdeaBadgeUrl, typeof (gdTop5IdeasBadgeUrl) === 'undefined' ? undefined : gdTop5IdeasBadgeUrl);
}
if (typeof (gdInitSubmitIdea) !== 'undefined' && gdInitSubmitIdea) {
    SubmitIdea.prototype.init();
}
if (typeof (gdInitIdea) !== 'undefined' && gdInitIdea) {
    var ideaComponent = new Idea(gdIdeaId, gdIdeaIsVoted);
    var favoriteBtnContainer = $('#favorite-btn-container');
    var favoriteComponent = new FavoriteButton(favoriteBtnContainer, gdIdeaId, gdIdeaIsFavorited);
}
if (typeof (gdInitComments) !== 'undefined' && gdInitComments) {
    Comments.prototype.init();
    CommentList.prototype.init();
}
if (typeof (gdCountdownTimerDeadline) !== 'undefined') {
    var countdownTimer = new CountdownTimer(gdCountdownTimerDeadline);
}
if (typeof (gdInitTooltips) !== 'undefined' && gdInitTooltips) {
    var tooltips = new Tooltips();
}
if (typeof (gdInitSiteStat) !== 'undefined' && gdInitSiteStat) {
    var siteStats = new SiteStats();
}
