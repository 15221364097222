import * as $ from "jquery";
import { VoteButton } from "./VoteButton";
import { getCsrfCookie } from "./Utils";
var Idea = /** @class */ (function () {
    function Idea(ideaId, isVoted) {
        this.ideaId = ideaId;
        this.bindDeleteButton();
        // Create vote button.
        var voteBtnContainer = $('#vote-btn-container');
        var voteButton = new VoteButton(voteBtnContainer, ideaId, isVoted, null);
    }
    ;
    Idea.prototype.bindDeleteButton = function () {
        var me = this;
        $(".delete-idea").on('click', function (e) {
            e.preventDefault();
            var $linkEle = $(this), $ideaEle = $linkEle.parents("[data-idea-id]"), ideaId = $ideaEle.data('idea-id'), $ele = $(e.currentTarget), ideaTitle = $('.idea__title').text().trim(), answer = confirm('Are you sure you want to delete the idea "' + ideaTitle + '"?');
            if (!answer) {
                return false;
            }
            $.ajax({
                url: "/api/ideas/" + ideaId,
                type: 'DELETE',
                headers: { 'X-CSRFToken': getCsrfCookie() },
            })
                .done(function (response) {
                window.location.href = response["redirectUrl"];
            })
                .fail(function (xhr) {
                me.showError("Sorry an error occurred. Please contact us and tell us what happened.");
            });
            return false;
        });
    };
    // TODO: Looks like there's custom message dialog modal dialog to display error messages
    Idea.prototype.showError = function (message) {
        // var $listOfIdeas = $( "#list-of-ideas" );
        // var ele = $listOfIdeas.prev();
        //
        // if( ele.hasClass( 'message-container' ) ) {
        // 	ele.remove();
        // }
        //
        // var messageEle = gd.forms.message.getSuccessContainer_();
        // messageEle.insertBefore( $listOfIdeas );
        //
        // messageEle.html( "<p>" + message + "</p>" );
        alert(message);
    };
    ;
    return Idea;
}());
export { Idea };
