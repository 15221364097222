import * as $ from "jquery";
var SiteStats = /** @class */ (function () {
    function SiteStats() {
        this.switchToTab('all-time');
        var me = this;
        $("#tab-all-time").on("click", function () {
            me.switchToTab('all-time');
        });
        $("#tab-weekly").on("click", function () {
            me.switchToTab('weekly');
        });
    }
    SiteStats.prototype.switchToTab = function (tab) {
        var tabBtn = "tab-" + tab;
        var tabContent = "tab-content-" + tab;
        // Update tab buttons
        $(".tab-btn").each(function () {
            var tab = $(this);
            if (tab.attr("id") === tabBtn) {
                tab.addClass("active");
            }
            else {
                tab.removeClass("active");
            }
        });
        $(".tab-content").each(function () {
            var tab = $(this);
            if (tab.attr("id") === tabContent) {
                tab.show();
            }
            else {
                tab.hide();
            }
        });
    };
    return SiteStats;
}());
export { SiteStats };
