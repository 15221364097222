import * as $ from "jquery";
var Background = /** @class */ (function () {
    function Background() {
        this.idCounter = 1;
        this.animSpeed = 10000;
        var me = this;
        $('.animated-background').each(function (i, el) {
            var id = 'animated-background-' + me.idCounter;
            me.idCounter++;
            $(el).attr('id', id);
            me.startAnimation('#' + id);
        });
    }
    ;
    Background.prototype.startAnimation = function (id) {
        var me = this;
        $(id).find('#state-1').hide();
        var morph = new SVGMorpheus(id, {
            rotation: 'none'
        });
        var loop = function (id) {
            morph.to('state-' + id, {
                duration: me.animSpeed
            });
            var next = id === 1 ? 2 : 1;
            setTimeout(loop.bind(null, next), me.animSpeed);
        };
        loop(1);
    };
    ;
    return Background;
}());
export { Background };
