import * as $ from "jquery";
import { VoteButton } from "./VoteButton";
import { getCsrfCookie } from "./Utils";
var IdeaModal = /** @class */ (function () {
    function IdeaModal(modalId, updateIdeaInParent) {
        var _this = this;
        // Object Properties
        this.modalId = modalId;
        this.updateIdeaInParent = updateIdeaInParent;
        this.currentIdea = null;
        // Append html for modal to page.
        $('body').append('<div class="modal fade idea-modal" id="' + this.modalId + '" tabindex="-1" role="dialog" aria-labelledby="'
            + this.modalId + 'Label" aria-hidden="true">' +
            '<div class="modal-dialog modal-lg" role="document">' +
            '<div class="modal-content">' +
            // Modal Header
            '<div class="modal-header">' +
            '  <a id="idea-link" href="#">' +
            '    <h5 class="modal-title idea-title" id="' + this.modalId + 'Label"></h5>' +
            '  </a>' +
            '  <button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
            '    <span aria-hidden="true">&times;</span>' +
            '  </button>' +
            '</div>' +
            // Modal Body
            '<div class="modal-body row">' +
            '  <section class="col-sm-8">' +
            '      <div class="idea-body-text"></div>' +
            '      <footer class="idea__footer">' +
            '          <article class="idea__author author author--footer">' +
            '              <div class="author__media">' +
            '                  <img src="" width="50" height="50" class="author__photo" alt="Profile Pic" />' +
            '              </div>' +
            '              <div class="author__main">' +
            '                  <h4 class="author__name">' +
            '                      <a href=""></a>' +
            '                  </h4>' +
            '                  <div class="author__meta">' +
            '                      <span class="author__region">' +
            '                          <img class="flag-icon" src="" alt="">' +
            '                              <span class="author-country"></span> | ' +
            '                      </span>' +
            '                      <span class="author__date"></span>' +
            '                  </div>' +
            '              </div>' +
            '          </article>' +
            '      </footer>' +
            '  </section>' +
            '  <section class="col-sm-4">' +
            '    <table class="modal-sidebar-table">' +
            '      <tr>' +
            '        <th>Vote</th>' +
            '        <td class="vote--idea">' +
            '          <div id="vote-btn-container"></div> ' +
            '        </td>' +
            '      </tr>' +
            '      <tr>' +
            '        <th>&nbsp;</th>' +
            '        <td class="vote--idea-next">' +
            '          <a id="next-idea" class="btn btn-shadow btn-white btn-sm">Next &gt;</a>' +
            '      </td>' +
            '      </tr>' +
            // Do not show votes and views when it is "this week's top 5"
            // '      <tr>' +
            // '        <th>Votes</th>' +
            // '        <td id="voteCount"></td>' +
            // '      </tr>' +
            // '      <tr>' +
            // '        <th>Views</th>' +
            // '        <td id="viewCount"></td>' +
            // '      </tr>' +
            '    </table>' +
            '  </section>' +
            '</div>' +
            // Close modal containing divs.
            '</div>' +
            '</div>' +
            '</div>');
        // Create vote button.
        var voteBtnContainer = $('#vote-btn-container');
        this.voteButton = new VoteButton(voteBtnContainer, 0, false, function (ideaId, newIsVotedState) { return _this.toggleVoteState(ideaId, newIsVotedState); });
    }
    IdeaModal.prototype.updateContent = function (idea, hasNextIdea, openNextIdea) {
        this.currentIdea = idea;
        $("#" + this.modalId + " .idea-title").html(idea.Title);
        $("#" + this.modalId + " #idea-link").attr('href', idea.UrlFriendlyUrl);
        $("#" + this.modalId + " .idea-body-text").html(idea.Body);
        $("#" + this.modalId + " .author__photo").attr('src', idea.AuthorProfileImageUrl);
        $("#" + this.modalId + " .author__name a").attr('href', idea.AuthorUrl).html(idea.UserNameOrGdNumber);
        $("#" + this.modalId + " .author__region .flag-icon")
            .attr('src', idea.FlagUrl)
            .attr('alt', idea.Country);
        $("#" + this.modalId + " .author__region .author-country").html(idea.Country);
        $("#" + this.modalId + " .author__date").html(idea.DateTime);
        $("#" + this.modalId + " #voteCount").html(idea.VoteCount.toString());
        if (!idea.UserNameOrGdNumber) {
            $("#" + this.modalId + " .author__name a").hide();
        }
        else {
            $("#" + this.modalId + " .author__name a").show();
        }
        if (!idea.Country) {
            $("#" + this.modalId + " .author__region").hide();
        }
        else {
            $("#" + this.modalId + " .author__region").show();
        }
        this.refreshInterfaceViewcount();
        // Vote button.
        this.voteButton.setNewIdea(idea.id, idea.IsVotedByCurrentUser);
        if (hasNextIdea) {
            $("#" + this.modalId + " #next-idea").show();
            $("#" + this.modalId + " #next-idea").click(function (event) {
                event.preventDefault();
                openNextIdea(event);
            });
        }
        else {
            $("#" + this.modalId + " #next-idea").hide();
        }
    };
    IdeaModal.prototype.refreshInterfaceViewcount = function () {
        if (this.currentIdea) {
            $("#" + this.modalId + " #viewCount").html(this.currentIdea.ViewCount.toString());
        }
    };
    IdeaModal.prototype.show = function () {
        // Trigger bootstrap's show modal function.
        //@ts-ignore
        $("#" + this.modalId).modal('show');
        // Update view count and hits if necessary. (will not increment if user has already viewed this idea)
        var me = this;
        if (this.currentIdea !== null) {
            $.ajax({
                headers: { 'X-CSRFToken': getCsrfCookie() },
                url: "api/ideas/" + this.currentIdea.id + "/increment-view-count",
                type: "POST",
                success: function (data) {
                    if (data.isUpdated) {
                        me.incrementViewcountInState();
                        me.refreshInterfaceViewcount(); // do this after updating count in state!
                    }
                },
                error: function () {
                    console.error('Error updating idea view count.');
                }
            });
        }
    };
    IdeaModal.prototype.incrementViewcountInState = function () {
        if (this.currentIdea) {
            this.currentIdea.ViewCount++;
            if (app.isLoggedIn) {
                this.currentIdea.IsViewedByCurrentUser = true;
            }
            this.updateIdeaInParent(this.currentIdea);
        }
    };
    IdeaModal.prototype.toggleVoteState = function (ideaId, newIsVotedState) {
        if (this.currentIdea === null) {
            console.error('Idea is null');
        }
        else if (this.currentIdea.id !== ideaId) {
            console.error('VoteButton id is out of sync with IdeaModal id.');
        }
        else {
            // Mutate frontend state.
            this.currentIdea.IsVotedByCurrentUser = newIsVotedState;
            var voteCountAddend = newIsVotedState ? 1 : -1;
            this.currentIdea.VoteCount += voteCountAddend;
            this.updateIdeaInParent(this.currentIdea);
        }
    };
    return IdeaModal;
}());
export { IdeaModal };
