import * as $ from "jquery";
export function FormElements() {
}
FormElements.prototype.className_ = "fancy-form-element";
FormElements.prototype.typeClassName_ = "fancy-form-element-";
FormElements.prototype.checkboxActiveClass_ = "fancy-form-checkbox-checked";
FormElements.prototype.checkboxInactiveClass_ = "fancy-form-checkbox-unchecked";
FormElements.prototype.arrowClass_ = "arrow";
FormElements.prototype.checkClass_ = "check";
FormElements.prototype.labelClass_ = "label";
FormElements.prototype.activateQuery_ = 'input[type="radio"], input[type="checkbox"], select';
FormElements.prototype.init = function () {
    FormElements.prototype.initInputClasses();
    FormElements.prototype.initFileInputs();
    // $( FormElements.prototype.activateQuery_ ).each( FormElements.prototype.initElement_ );
};
FormElements.prototype.initElement_ = function (index, ele) {
    var $ele = $(ele), tagName = $ele.prop('tagName').toLowerCase(), typeAttr = $ele.prop('type').toLowerCase();
    if (tagName == "select") {
        var $wrappedEle = FormElements.prototype.wrap_($ele, tagName);
        FormElements.prototype.initSelect_($wrappedEle);
        FormElements.prototype.initSelectEvent_($wrappedEle, $ele);
    }
    else if (typeAttr == "checkbox") {
        var $wrappedEle = FormElements.prototype.wrap_($ele, "input-" + typeAttr);
        FormElements.prototype.initCheckbox_($wrappedEle);
        FormElements.prototype.initCheckBoxEvent_($wrappedEle, $ele);
    }
};
FormElements.prototype.initSelect_ = function ($ele) {
    var arrowEle = $('<span></span>'), labelEle = $('<span></span>');
    $(arrowEle)
        .addClass(FormElements.prototype.arrowClass_)
        .appendTo($ele);
    $(labelEle)
        .addClass(FormElements.prototype.labelClass_)
        .appendTo($ele);
};
FormElements.prototype.updateSelectState_ = function ($wrappedEle, $inputEle) {
    var $label = $("span." + FormElements.prototype.labelClass_, $wrappedEle), inputValue = $.trim($($inputEle.find("option").get($inputEle.get(0).selectedIndex)).text());
    if ($label.length > 0) {
        $label.text(inputValue);
    }
};
FormElements.prototype.initSelectEvent_ = function ($wrappedEle, $inputEle) {
    $inputEle.on('change', function () {
        FormElements.prototype.updateSelectState_($wrappedEle, $inputEle);
    });
    FormElements.prototype.updateSelectState_($wrappedEle, $inputEle);
};
FormElements.prototype.initCheckbox_ = function ($ele) {
    var $checkEle = $("<span></span>");
    $checkEle
        .addClass(FormElements.prototype.checkClass_);
    $ele.append($checkEle);
};
FormElements.prototype.updateCheckboxState_ = function (isChecked, $wrappedEle, $checkboxEle) {
    if (isChecked) {
        $wrappedEle
            .removeClass(FormElements.prototype.checkboxInactiveClass_)
            .addClass(FormElements.prototype.checkboxActiveClass_);
    }
    else {
        $wrappedEle
            .removeClass(FormElements.prototype.checkboxActiveClass_)
            .addClass(FormElements.prototype.checkboxInactiveClass_);
    }
    $checkboxEle.prop('checked', (isChecked) ? 'checked' : '');
};
FormElements.prototype.initCheckBoxEvent_ = function ($wrappedEle, $checkboxEle) {
    $wrappedEle
        .on('click', function (e) {
        e.preventDefault();
        var isChecked = $checkboxEle.is(":checked");
        // use a timeout to let the label or whatever element that triggered the change
        // to actually change, then we'll update the UI
        setTimeout(function () {
            var checkedAgain = $checkboxEle.is(":checked");
            if (checkedAgain == isChecked) {
                isChecked = !isChecked;
            }
            FormElements.prototype.updateCheckboxState_(isChecked, $wrappedEle, $checkboxEle);
        }, 1);
    });
    FormElements.prototype.updateCheckboxState_($checkboxEle.is(":checked"), $wrappedEle, $checkboxEle);
};
FormElements.prototype.wrap_ = function ($ele, classType) {
    var tagClass = FormElements.prototype.typeClassName_ + classType;
    $ele
        .css({ 'opacity': 0 })
        .wrap('<span />')
        .parent()
        .addClass(FormElements.prototype.className_)
        .addClass(tagClass);
    return $ele.parent();
};
FormElements.prototype.initFileInputs = function () {
    $(document).on('change', 'input[type="file"].custom-file-input', function (e) {
        FormElements.prototype.handleFileInputChange($(this));
    });
};
FormElements.prototype.handleFileInputChange = function ($input) {
    var $target = $input.next('span');
    if (!$target.attr('data-original-content')) {
        $target.attr('data-original-content', $target.attr('data-content'));
    }
    var input = $input.get(0);
    var name = (input && input.files && input.files.length && input.files[0].name) ? input.files[0].name : $input.val();
    if (!name || name === '') {
        name = $target.attr('data-original-content');
    }
    $target.attr('data-content', name);
};
FormElements.prototype.initInputClasses = function () {
    $(document).on('keyup change focus blur touchend click change', 'input, textarea, select', function () {
        FormElements.prototype.renderInputClasses($(this));
    });
    $('input, textarea, select').each(function () {
        FormElements.prototype.renderInputClasses($(this));
    });
    $('body').on('change', 'select', function () {
        $(this).blur();
    });
};
FormElements.prototype.renderInputClasses = function ($input) {
    var $group = $input.parents('.form-group');
    var val = $input.val();
    var isFocus = !!$input.is(':focus');
    $group.toggleClass('is-focus', isFocus);
    $group.toggleClass('is-blur', !isFocus);
    $group.toggleClass('is-empty', !val.length || val == '-1');
    $group.toggleClass('is-full', !!val.length && val != '-1');
};
