import { Comments } from "./Comments";
import { Paginate } from "./Paginate";
import * as $ from "jquery";
export function CommentList() { }
CommentList.prototype.init = function () {
    if ($('#commentsContainer').length > 0) {
        this.currentPage = 1;
        this.loadComments(true, true);
    }
};
CommentList.prototype.submitComment = function () { };
CommentList.prototype.render = function (data, first) {
    var spinner = $('#commentsSpinner');
    var container = $('#commentsContainer');
    spinner.hide();
    container.show();
    container.html("");
    var ul = $('<ul class="current-comments comments__comments">');
    data.comments.forEach(function (comment) {
        var li = $('<li data-comment-id="' + comment.Id + '" id ="comment-' + comment.Id + '">');
        ul.append(li);
        var article = $('<article class="comment">');
        li.append(article);
        if (comment.Demoted) {
            li.append('<p class="demoted profile-card-parent">');
        }
        else {
            li.append('<p class="profile-card-parent">');
        }
        var authorArticle = $('<article class="comment__author author author--footer">');
        article.append(authorArticle);
        authorArticle.append($('<div class="author__media"><img src="' + comment.AuthorAvatar + '" width="50" height="50" class="author__photo"/></div>'));
        var main = $('<div class="author__main">');
        authorArticle.append(main);
        if (!comment.AuthorId) {
            main.append('<h4 class="author__name">Anonymous</h4>');
        }
        else {
            main.append('<h4 class="author__name"><a href="/user/' + comment.AuthorId + '">' + comment.UserName + '</a></h4>');
        }
        var meta = $('<div class="author__meta">');
        main.append(meta);
        if (comment.AuthorId && comment.UserCountryFullName && comment.UserCountryFlag) {
            meta.append('<span class="author__region">' +
                '<img class="flag-icon" src="' + comment.UserCountryFlag + '" alt="' + comment.UserCountryFullName + '">' +
                comment.UserCountryFullName + '</span> | ');
        }
        meta.append('<span class="author__date">' + comment.Datetime + '</span>');
        article.append('<span class="comment-body">' + comment.CommentBody + '</span>');
        article.append('<p/>');
        var rating = $('<div class="comment-rating">');
        var cssClasses = ['comment-current-voting-value', 'vote', 'vote--comment'];
        if (comment.IsVotedUpByCurrentUser) {
            cssClasses.push('comment-voted-yes vote--up');
        }
        else if (comment.IsVotedDownByCurrentUser) {
            cssClasses.push('comment-voted-no vote--down');
        }
        var votingValue = $('<span class="' + cssClasses.join(' ') + '">');
        var votingContainer = $('<span class="comment-vote-yes-container">');
        votingValue.append(votingContainer);
        var up = $('<a class="comment-vote-button rating-yes vote__up" href="' + comment.URL + '">' +
            '<i class="icon-chevron-up"></i>' +
            '<span class="vote__value">' + comment.UpVotes + '</span>' +
            '</a>');
        up.on('click', function (e) {
            var newState;
            if (comment.IsVotedDownByCurrentUser) {
                // Set net state to "abstain"
                comment.IsVotedDownByCurrentUser = false;
                comment.IsVotedUpByCurrentUser = false;
                newState = 9;
            }
            else {
                // Set new state to "upvote"
                comment.IsVotedDownByCurrentUser = false;
                comment.IsVotedUpByCurrentUser = true;
                newState = 1;
            }
            Comments.prototype.commentOnClick(e, newState);
        });
        votingContainer.append(up);
        votingContainer = $('<span class="comment-vote-no-container">');
        votingValue.append(votingContainer);
        var down = $('<a class="comment-vote-button rating-no vote__down" href="' + comment.URL + '">' +
            '<i class="icon-chevron-down"></i>' +
            '<span class="vote__value">' + comment.DownVotes + '</span>' +
            '</a>');
        down.on('click', function (e) {
            var newState;
            if (comment.IsVotedUpByCurrentUser) {
                // Set net state to "abstain"
                comment.IsVotedDownByCurrentUser = false;
                comment.IsVotedUpByCurrentUser = false;
                newState = 9;
            }
            else {
                // Set new state to "downvote"
                comment.IsVotedDownByCurrentUser = true;
                comment.IsVotedUpByCurrentUser = false;
                newState = -1;
            }
            Comments.prototype.commentOnClick(e, newState);
        });
        votingContainer.append(down);
        rating.append(votingValue);
        article.append(rating);
    });
    container.append(ul);
    if (first) {
        this.renderPageNumbers(data);
    }
};
CommentList.prototype.renderPageNumbers = function (data) {
    var spinner = $('#commentsSpinner');
    var me = this;
    var container = $('#commentsPageNumbers');
    this.paginationDiv = $("<div id='pageNumberContainer' class='pageNumberContainer' style='width: 100%'>");
    container.append(this.paginationDiv);
    var pageNumberClicked = function () {
        $(this).parent().find('.currentPage').removeClass('currentPage');
        $(this).addClass('currentPage');
        var value = $(this).text();
        if (value === '>') {
            me.currentPage++;
        }
        else if (value === '>>') {
            me.currentPage += 10;
        }
        else if (value === '<') {
            me.currentPage--;
        }
        else if (value === '<<') {
            me.currentPage -= 10;
        }
        else {
            me.currentPage = parseInt(value);
        }
        if (me.currentPage < 1) {
            me.currentPage = 1;
        }
        if (me.currentPage >= data.totalPages) {
            me.currentPage = parseInt(data.totalPages);
        }
        me.renderPageButtonsForComments(data, pageNumberClicked);
        spinner.show();
        $('.idea__comments')[0].scrollIntoView();
        me.loadComments(false, true);
    };
    this.renderPageButtonsForComments(data, pageNumberClicked);
};
CommentList.prototype.renderPageButtonsForComments = function (data, pageNumberClicked) {
    Paginate.prototype.renderPageButtons(this.paginationDiv, parseInt(data.totalComments), parseInt(this.currentPage), parseInt(data.commentsPerPage), parseInt(data.totalPages), pageNumberClicked);
};
CommentList.prototype.loadComments = function (first, hideExisting) {
    var me = this;
    var json = { currentPage: this.currentPage };
    // const spinner = $('#commentsSpinner');
    var container = $('#commentsContainer');
    if (hideExisting) {
        container.hide();
    }
    if (this.ajaxCall) {
        this.ajaxCall.abort();
    }
    var ideaID = $('.idea--details[data-idea-id]').attr('data-idea-id');
    this.ajaxCall = $.ajax({
        url: "/api/ideas/" + ideaID + "/comments",
        type: "GET",
        data: json,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (data) {
            $('.comments__submit').show();
            me.currentPage = data.currentPage;
            me.render(data, first);
        },
        error: function () {
        }
    });
};
